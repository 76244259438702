@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900");

.App {
  font-family: "Merriweather";
  padding: 28px;
  color: rgb(34, 13, 24);

  a {
    color: rgb(34, 13, 24);
  }

  .Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;

    .logo {
      width: 195px;
    }

    .button {
      width: 44px;
      cursor: pointer;

      &.margin {
        margin-right: 16px;
      }
    }
  }

  .Body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    .col1 {
      .inner-container {
        max-width: 358px;

        .main-text-container {
          padding-left: 36px;
          padding-top: 28px;
          padding-bottom: 46px;
          font-size: 18px;
          letter-spacing: -0.5px;
          line-height: 28px;

          &.no-padding {
            padding-top: 0px;
            padding-bottom: 0px;
          }
        }

        .main-text {
          font-size: 24px;
          line-height: 33px;
    font-weight: bold;
        }

        .sub-text {
          font-size: 18px;
        }
      }
    }

    .col2 {
      .memepack {
        text-align: right;
        font-size: 18px;
        margin-top: 24px;

        span {
          margin-right: 4px;
        }
      }
    }

    .col3 {
      margin-top: 24px;

      .inner-container {
        max-width: 358px;
        line-height: 32px;
        padding-left: 36px;
        font-size: 18px;

        .button {
          display: none;
        }

        .inner-header {
          font-size: 21px;
          margin-bottom: 4px;
          font-weight: bold;
        }

        .light-text {
          color: rgb(57, 63, 68);
          font-size: 12px;
          font-style: italic;
        }

        .ticker {
          line-height: 28px;
          padding-right: 54px;
        }
      }

      .main {
        margin-top: 40px;
        margin-bottom: 24px;
      }

      .disclaimer {
        font-family: "Lora";
        font-size: 12px;
        color: rgb(57, 63, 68);
        letter-spacing: -0.35;
        opacity: 1;
        font-style: Italic;
        line-height: initial;
        margin-top: 36px;
      }
    }

    .main {
      width: 414px;
    }

    .button {
      width: 358px;
      margin: 32px 0 24px 28px;
      cursor: pointer;
    }
  }
}

@media (max-width: 1350px) {
  .App {
    padding: 0;

    .Header {
      max-width: initial;
      margin-top: 18px;
      padding: 0 18px;
    }

    .Body {
      flex-direction: column;
      padding-left: 0;
      max-width: initial;

      .main {
        width: 100vw;
      }

      .col3 {
        margin-top: 48px;

        .main {
          margin-top: 48px;
        }

        .inner-container {
          .button {
            display: flex;
            margin: 64px 0 32px;
          }
        }

        .disclaimer {
          margin-bottom: 40px;
        }
      }

      .button {
        width: 85vw;
      }
    }

    .col2 {
      margin-top: 48px;
      .memepack {
        text-align: center;
        padding-right: 52px;
      }
    }
  }
}